import { Component, Input, Inject } from '@angular/core';
import { SpinnerRef } from './overlay-ref.service';
import { SPINNER_DATA } from './spinner-message.token';
import { Message } from './overlay-spinner.service';

@Component({
  selector: 'app-sppiner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})

export class FilePreviewOverlayComponent {

  constructor(
    public dialogRef: SpinnerRef,
    @Inject(SPINNER_DATA) public message?: any) { }
}
