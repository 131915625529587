export const fr_translation =
{
  'zuora': {
    'title': 'Mode De Paiement',
    'CC-label': 'Payez en toute sécurité avec votre carte de crédit. Veuillez entrer les renseignements de votre carte de crédit pour continuer.',
    'ACH-label': 'Fr Pay securely using your checking account.  Please enter your bank account information to proceed.',
    'button': 'Continuer pour passer en revue',
    // tslint:disable-next-line:max-line-length
    'error': '​​Une erreur de serveur temporaire est survenue lors du traitement de votre demande. Veuillez confirmer que l’information entrée est correcte. Si ce message s’affiche de nouveau, veuillez fermer l’app et réessayer plus tard.​',
    'tmna': {
      'consent-cc-one': 'N/A',
      'consent-cc-two': '',
      'consent-cc-three': '',
      'consent-ach-one': 'N/A',
      'consent-ach-two': '',
      'consent-ach-three': ''
    },
    'tdpr': {
      'consent-cc-one': 'N/A',
      'consent-cc-two': '',
      'consent-cc-three': '',
      'consent-ach-one': 'N/A',
      'consent-ach-two': '',
      'consent-ach-three': ''
    },
    'tci': {
      'consent-cc-one': 'En cochant cette case, j’autorise par la présente que les frais mensuels actuels et futurs soient portés à la carte de crédit que j’ai fournie, comme décrit ici et dans les ',
      'consent-cc-two': 'conditions d’utilisation des Services connectés',
      'consent-cc-three': ', y compris la politique de remboursement.',
      'consent-ach-one': 'N/A',
      'consent-ach-two': '',
      'consent-ach-three': ''
    },
    'default-payment': '​Méthode de paiement par défaut​'
  }
}
