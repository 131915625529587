<div class="container">
  <div class="spinner-container">
    <mat-spinner class="spinner" color="primary" [diameter]=40 [strokeWidth]=6 ></mat-spinner>
  </div>
  <div *ngIf="message" class="message">
    <span>
      {{message.value}}
    </span>
  </div>
</div>
