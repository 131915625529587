import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatToolbarModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatCardModule,
  MatDividerModule,
  MatExpansionModule,
  MatTableModule,
  MatIconModule,
  MatSnackBarModule,
  MatSortModule,
  MatPaginatorModule,
  MatTabsModule,
  MatCheckboxModule,
  MatInputModule,
  MatSidenavModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatTooltipModule
} from '@angular/material';

@NgModule({
  imports: [
    MatButtonModule,
    MatToolbarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatExpansionModule,
    MatTableModule,
    MatSnackBarModule,
    MatTabsModule,
    MatCheckboxModule,
    MatInputModule,
    MatSidenavModule,
    MatMenuModule,
    MatTooltipModule
  ],
  exports: [
    MatButtonModule,
    MatToolbarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatExpansionModule,
    MatTableModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatTabsModule,
    MatCheckboxModule,
    MatInputModule,
    MatSidenavModule,
    MatMenuModule,
    MatTooltipModule
  ],
})

export class PortalMaterialModule { }
