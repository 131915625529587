export const en_translation =
{
  'zuora': {
    'title': 'Payment Method',
    'CC-label': 'Pay securely using your credit card. Please enter your credit card information to proceed.',
    'ACH-label': 'Pay securely using your checking account.  Please enter your bank account information to proceed.',
    'button': 'Continue to Review',
    // tslint:disable-next-line:max-line-length
    'error': '​The server encountered a temporary error while trying to complete your request. Please confirm the information entered is correct. If you continue to see this message, close the app and try again.',
    'tmna': {
      'consent-cc-one': 'Checking this box authorizes the scheduled charges to the credit card provided, as described in the ',
      'consent-cc-two': 'Terms of Use',
      'consent-cc-three': ' including the refund policy.',
      'consent-ach-one': 'Checking this box authorizes the scheduled charges to the checking account provided, as described in the ​',
      'consent-ach-two': 'Terms of Use',
      'consent-ach-three': ' including the refund policy.​​'
    },
    'tdpr': {
      'consent-cc-one': 'By checking this box, I hereby authorize the scheduled charges to be made to the credit card I have provided, as described here and in the ',
      'consent-cc-two': 'Connected Services Terms of Use',
      'consent-cc-three': ', including the refund policy.',
      'consent-ach-one': 'By checking this box, I hereby authorize the scheduled charges to be made to the bank account provided, as described here and in the ',
      'consent-ach-two': 'Connected Services Terms of Use​​',
      'consent-ach-three': ', including the refund policy.​​'
    },
    'tci': {
      'consent-cc-one': 'By checking this box, I hereby authorize the current and future monthly charges to be made to the credit card I have provided, as described here and in the ',
      'consent-cc-two': 'Connected Services Terms of Use',
      'consent-cc-three': ', including the refund policy.',
      'consent-ach-one': 'N/A',
      'consent-ach-two': '',
      'consent-ach-three': ''
    },
    'default-payment': 'Default Payment Method'
  }
};
