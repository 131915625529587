import { en_translation } from './en';
import { es_translation } from './es';
import { fr_translation } from './fr';

export const translations = {
  en_translation,
  es_translation,
  fr_translation
};

