import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Redux
import { DispatcherModule } from './store/dispatcher.module';
import { StoreModule } from './store/store.module';
import { NgReduxModule } from '@angular-redux/store';

// Service
import { TranslationService } from './common/services/languages/translation.service';
import { ServiceWorkerModule } from '@angular/service-worker';
// Module
import { LanguageModule } from './common/services/languages/language.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PortalMaterialModule } from './material.components.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SpinnerOverlayModule } from './common/components/spinner-overlay.module';

// Components
import { AppComponent } from './app.component';
import { ZuoraComponent } from './zuora/zuora.component';

@NgModule({
  entryComponents: [
  ],
  declarations: [
    AppComponent,
    ZuoraComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DispatcherModule,
    PortalMaterialModule,
    BrowserAnimationsModule,
    LanguageModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgReduxModule,
    StoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    SpinnerOverlayModule,
  ],
  providers: [
    TranslationService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
