export const environment = {
  production: false,
  uri: 'https://sandbox.eu.zuora.com/apps/PublicHostedPageLite.do',
  paymentGateways:
  {
    paymentGateway_tmna: 'TMNA Servco Telematics-Chase',
    paymentGateway_tci: 'TCI Telematics Chase',
    paymentGateway_tdpr: 'TDPR - Telematics - Chase',
    paymentGateway_servco: 'TMNA Servco Telematics-Chase',
    paymentGateway_tmex: 'TMEX - Telematics - Test',
  },
  paymentPageIds:
  {
    tmna_servco:
    {
      t_cc_paymentPageId: '8adc84828098bbcc0180af065473236b',
      t_ach_paymentPageId: '8adc84828098bbcc0180af22c24d3c89',
      l_cc_paymentPageId: '8adcd67f8098cf6a0180af1a34da2e6e',
      l_ach_paymentPageId: '8adc84828098bbcc0180af2614a03e5c'
    },
    tdpr:
    {
      t_cc_paymentPageId: '8adc83238098bbcd0180af29998e4db4',
      l_cc_paymentPageId: '8adc83238098bbcd0180af2d3ed94f18'
    },
    tci:
    {
      t_cc_paymentPageId: '8adc99998098bbd10180af30dfd64f65',
      l_cc_paymentPageId: '8adc83238098bbcd0180af3322d350cf'
    },
    tmex: {
      t_cc_paymentPageId: '8adcd9eb79d2c41e0179d3d9f7c87c5a',
      l_cc_paymentPageId: '8adc9dee78454279017847a9d2ba09dc'
    }
  }
};
