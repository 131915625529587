export const links = {
  toyota: {
    tci: {
      en: 'https://www.toyota.ca/connectedservices-termsofuse',
      fr: 'https://www.toyota.ca/servicesconnectés-conditionsdutilisation'
    },
    tmna: {
      en: 'https://www.toyota.com/privacyvts/'
    },
    tdpr: {
      en: 'https://www.toyotausvi.com/assets/pdf/connected_services_terms%20_of_use.pdf',
      es: 'https://www.toyotapr.com/assets/pdf/terminos_de_uso_servicios_conectados.pdf'
    },
    servco: {
      en: 'https://www.toyota.com/privacyvts/'
    }
  },
  lexus: {
    tci: {
      en: 'https://www.lexus.ca/enform-termsofuse',
      fr: 'https://www.lexus.ca/enform-conditionsdutilisation'
    },
    tmna: {
      en: 'https://www.lexus.com/privacyvts/'
    },
    tdpr: {
      en: 'https://www.lexuspr.com/assets/pdf/Lexus_Enform_Terms_and_Conditions.pdf',
      es: 'https://www.lexuspr.com/assets/pdf/Lexus_Enform_Terms_and_Conditions.pdf'
    },
    servco: {
      en: 'https://www.lexus.com/privacyvts/'
    }
  }
};
