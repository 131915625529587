import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslationService } from './common/services/languages/translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  constructor(
    public dialog: MatDialog,
    public translate: TranslationService
  ) { }
}
