import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from './multiple-loader/multiple-loader';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: true
    })
  ],
  exports: [TranslateModule],
})

export class LanguageModule {
  constructor() {
  }
}

export function createTranslateLoader(http: HttpClient) {
  const resources: object[] = [
    { prefix: './assets/ctp/i18n/', suffix: '.json' },
  ];
  return new MultiTranslateHttpLoader(http, resources);
}
