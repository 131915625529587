export const es_translation =
{
  'zuora': {
    'title': 'Método De Pago',
    'CC-label': 'Pague con seguridad usando su tarjeta de crédito. Por favor, introduzca la información de su tarjeta de crédito para proceder.',
    'ACH-label': 'Pague de forma segura con su cuenta de banco. Ingrese la información de su cuenta de banco para continuar.​',
    'button': 'Continuar con la revisión',
    // tslint:disable-next-line:max-line-length
    'error': '​El servidor ha encontrado un error temporal al intentar completar su solicitud.  Por favor, confirme que la información introducida es correcta.  Si continúa viendo este mensaje, cierre la aplicación e inténtelo de nuevo.​​',
    'tmna': {
      'consent-cc-one': 'N/A',
      'consent-cc-two': '',
      'consent-cc-three': '',
      'consent-ach-one': 'N/A',
      'consent-ach-two': '',
      'consent-ach-three': ''
    },
    'tdpr': {
      'consent-cc-one': 'Al marcar esta casilla, autorizo a que se realicen los cargos mensuales actuales y futuros a la tarjeta de crédito que proporcioné, como se describe aquí y en los ',
      'consent-cc-two': 'Términos de Uso de los Servicios Conectados',
      'consent-cc-three': ', incluida la política de reembolso.',
      // tslint:disable-next-line:max-line-length
      'consent-ach-one': 'Al marcar esta casilla,  autorizo ​​a que se realicen los cargos mensuales actuales y futuros a la tarjeta de cuenta de banco que proporcioné, como se describe aquí y en los ',
      'consent-ach-two': 'Términos de Uso de los Servicios Conectados',
      'consent-ach-three': ', incluida la política de reembolso.'
    },
    'tci': {
      'consent-cc-one': 'N/A',
      'consent-cc-two': '',
      'consent-cc-three': '',
      'consent-ach-one': 'N/A',
      'consent-ach-two': '',
      'consent-ach-three': ''
    },
    'default-payment': '​Método de pago predeterminado​'
  }
}
