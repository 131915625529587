import { Injectable, Inject, OnInit, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { FilePreviewOverlayComponent } from './spinner.component';

import { SpinnerRef } from './overlay-ref.service';
import { SPINNER_DATA } from './spinner-message.token';

export interface Message {
  value: string;
}

interface SpinnerConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  message?: Message;
}

const DEFAULT_CONFIG: SpinnerConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'spinner-overlay',
  message: null
};

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  open(config: SpinnerConfig = {}) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new SpinnerRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    return dialogRef;
  }

  private createOverlay(config: SpinnerConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: SpinnerConfig, dialogRef: SpinnerRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(FilePreviewOverlayComponent, null, injector);
    const containerRef: ComponentRef<FilePreviewOverlayComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: SpinnerConfig, dialogRef: SpinnerRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(SpinnerRef, dialogRef);
    injectionTokens.set(SPINNER_DATA, config.message);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: SpinnerConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}
