import { Action, AuthActions } from './auth-actions';
import { tassign } from 'tassign';
import { Auth, initialAuth } from './auth-state.model';

/**
* FileReducer Will excecute the according triggered in the dispatcher
* @param state Store model
* @param action The action that will affect the store
**/
export const AuthReducer = function (state: Auth = initialAuth, action: Action): Auth {
  switch (action.type) {
    case AuthActions.SET_AVAILABLE_TYPE:
      return tassign(state, {
        userAvailableRoles: action.payload.type,
        accessType: action.payload.accessType,
        isOPUser: action.payload.isOPUser
      });
    case AuthActions.SET_TOKEN:
      return tassign(state, {
        token: action.payload.token,
      });
    case AuthActions.SET_USER_DETAILS:
      return tassign(state, {
        email: action.payload.email,
        name: action.payload.name,
        role: action.payload.role
      });
    case AuthActions.SET_GUID:
      return tassign(state, {
        guid: action.payload.guid
      });
    case AuthActions.SET_OBJECTID:
      return tassign(state, {
        objectId: action.payload.objectId
      });
    case AuthActions.SET_LOGIN:
      return tassign(state, {
        isLoggedIn: action.payload.isLoggedIn
      });
    default:
      return state;
  }
};


