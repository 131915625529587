import { AppState } from './state.model';
import { Reducer, combineReducers } from 'redux';
import { AuthReducer } from './auth/auth-reducer';
import { tassign } from 'tassign';

const appReducer: Reducer<AppState> = combineReducers({
  auth: AuthReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = {
      auth: tassign(state.auth, { guid: '' }),
    };
  }
  return appReducer(state, action);
};






