/**
* @template auth model declaration for storing user type
**/
export interface Auth {
  userAvailableRoles: Array<string>;
  accessType: Array<string>;
  token: string;
  name: string;
  email: string;
  password: string;
  guid: string;
  isLoggedIn: boolean;
  role: Array<string>;
  objectId: string;
  isOPUser: boolean;
}
/**
* @const initialAuth State model for storing user type
**/
export const initialAuth: Auth = {
  userAvailableRoles: [],
  accessType: [],
  token: '',
  name: '',
  email: '',
  password: '',
  guid: '',
  isLoggedIn: false,
  role: [],
  objectId: '',
  isOPUser: false
};
