import { Injectable } from '@angular/core';
import { FluxStandardAction } from 'flux-standard-action';
export type Action = FluxStandardAction<any, any>;

@Injectable()
export class AuthActions {
  /**
  * @arg Declaration of the action to store the user type
  **/
  static readonly SET_AVAILABLE_TYPE = 'SET_AVAILABLE_TYPE';
  /**
  * @arg Declaration of the action to store the Token
  **/
  static readonly SET_TOKEN = 'SET_TOKEN';
  /**
  * @arg Declaration of the action to set the dealer details
  **/
  static readonly SET_USER_DETAILS = 'SET_USER_DETAILS';
  /**
  * @arg Declaration of the action to get the Token
  **/
  static readonly FETCH_TOKEN = 'FETCH_TOKEN';
  /**
  * @arg Declaration of the action to store the GUID
  **/
  static readonly SET_GUID = 'SET_GUID';
  /**
  * @arg Declaration of the action to store the azure login
  **/
  static readonly SET_LOGIN = 'SET_LOGIN';
  /**
  * @arg Declaration of the action to store the GUID
  **/
  static readonly SET_OBJECTID = 'SET_OBJECTID';
  static setAvailabletype(type: Array<string>) {
    let accessType = [];
    if (type.indexOf('APPROVER') > -1 || type.indexOf('ADMIN') > -1) {
      accessType = ['submitted', 'approved'];
    } else if (type.indexOf('SUBMITTER') > -1 || type.indexOf('REVIEWER') > -1) {
      accessType = ['submitted'];
    }
    const isOPUser = type.indexOf('OP_ADMIN') > -1 ||
      type.indexOf('OP_READ') > -1;
    return {
      type: AuthActions.SET_AVAILABLE_TYPE,
      payload: {
        type,
        accessType,
        isOPUser
      }
    };
  }
  static setUserDetails(user: object) {
    return {
      type: AuthActions.SET_USER_DETAILS,
      payload: {
        email: user['preferred_username'],
        name: user['name'],
        role: user['roles']
      }
    };
  }

  /**
  * @method setGuid Will dispatch the action to store the GUID
  * @param Guid Value of the GUID for the user account
  **/
  static setGuid(guid: string) {
    return {
      type: AuthActions.SET_GUID,
      payload: { guid }
    };
  }

  /**
  * @method setObjectId Will dispatch the action to store the objectId
  * @param ObjectId Value of the objectId for the user account
  **/
  static setObjectId(objectId: string) {
    return {
      type: AuthActions.SET_OBJECTID,
      payload: { objectId }
    };
  }
  /**
  * @method setLogin Will dispatch the action to store the azure login
  * @param isLoggedIn boolean to store login
  **/
  static setLogin(isLoggedIn: boolean) {
    return {
      type: AuthActions.SET_LOGIN,
      payload: { isLoggedIn: isLoggedIn }
    };
  }

  /**
  * @method setToken Will dispatch the action to store the GUID
  * @param token Value of the GUID for the user account
  **/
  static setToken(token: string) {
    return {
      type: AuthActions.SET_TOKEN,
      payload: { token }
    };
  }
  /**
  * @method logout logout
  * @return void
  **/
  static logout() {
    return {
      type: AuthActions.SET_LOGIN,
      payload: { isLoggedIn: false }
    };
  }
}
